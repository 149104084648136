import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {Link} from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import * as classes from './ProjectCard.module.css';

function ProjectCard({
  projectName,
  projectDescription,
  logoSrc,
  featuredImageSrc,
  solutionCategories,
  isLogoVisible,
  projectDescriptionClass,
  featuredImageLoading,
  caseStudyUrl,
  isFeaturedCaseStudy,
}) {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {relativeDirectory: {eq: "portfolio/projects/featured-images"}}) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 500, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  const match = React.useMemo(
    () =>
      data.allFile.edges.find(
        ({node}) => `portfolio/projects/featured-images/${featuredImageSrc}` === node.relativePath
      ),
    [data, featuredImageSrc]
  );
  return (
    <article className={`${classes.card}`}>
      <header className={classes.header}>
        <div className={classes.featuredImage}>
          <Img
            className={classes.featuredImage}
            imgStyle={{objectFit: 'cover'}}
            placeholderStyle={{objectFit: 'cover'}}
            objectFit="cover"
            objectPosition="50% 50%"
            fluid={match.node.childImageSharp.fluid}
            alt={projectName}
            fadeIn={false}
            loading={featuredImageLoading}
          />
        </div>
        {isLogoVisible && (
          <div className={`${classes.logoContainer} ${classes.rectLogo}`}>
            <img className={classes.logo} src={logoSrc} alt={projectName} />
          </div>
        )}
      </header>
      <div style={{padding: 30, paddingTop: 50, paddingBottom: 20}}>
        <section style={{paddingBottom: 30}}>
          <div className={`${classes.projectDescription} ${projectDescriptionClass ?? ''} m-0`}>
            {projectDescription}
          </div>
        </section>
      </div>
      {solutionCategories && (
        <footer className={classes.cardFooter}>
          {isFeaturedCaseStudy && (
            <Link to={caseStudyUrl} className={classes.casestudyLink}>
              View Case Study
              <svg
                width="15"
                height="15"
                viewBox="0 0 21 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                color="inherit"
              >
                <path
                  d="M1.11358 5.34386L18.332 5.30243L14.6914 2.04963C14.446 1.82173 14.4256 1.4488 14.6505 1.20017C14.8755 0.951553 15.2437 0.930835 15.4891 1.15874L20.2955 5.50961C20.4182 5.6132 20.5 5.77895 20.5 5.9447C20.5 6.11045 20.4386 6.27619 20.3159 6.37979L15.5096 10.8343C15.3868 10.9378 15.2437 11 15.1005 11C14.9573 11 14.7937 10.9171 14.671 10.7928C14.446 10.5442 14.4665 10.1713 14.7119 9.94336L18.4343 6.5041L1.09313 6.54553C0.765885 6.54553 0.5 6.27619 0.5 5.9447C0.5 5.6132 0.765885 5.34386 1.11358 5.34386Z"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeWidth="0.3"
                />
              </svg>
            </Link>
          )}
        </footer>
      )}
    </article>
  );
}

ProjectCard.propTypes = {
  logoSrc: PropTypes.string.isRequired,
  featuredImageSrc: PropTypes.string.isRequired,
  solutionCategories: PropTypes.array,
  projectName: PropTypes.string.isRequired,
  projectDescription: PropTypes.string.isRequired,
  caseStudyUrl: PropTypes.string,
  isLogoRound: PropTypes.bool,
  featuredImageLoading: PropTypes.oneOf(['lazy', 'eager']),
};

ProjectCard.defaultProps = {
  isLogoRound: false,
  featuredImageLoading: 'eager',
};

export default ProjectCard;
